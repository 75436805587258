import React, { useEffect, useState } from "react";
import { Toast, Spinner, Button } from "react-bootstrap";

import { firebase } from "../../firebaseConfig";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const firedb = firebase.database();

const LocationMap = () => {
  const [imgUrl, setimgUrl] = useState("");
  const [uploadImage, setuploadImage] = useState(null);
  const [locMapText, setlocMapText] = useState("");

  const [showToast, setshowToast] = useState(false);
  const [showSpinner, setshowSpinner] = useState(false);

  useEffect(() => {
    firedb
      .ref("UPC")
      .child("LOCATION_MAP")
      .once("value", (snapshot) => {
        setlocMapText(snapshot.val().locText);
        setimgUrl(snapshot.val().imgUrl);
      });
  }, []);

  const handleUpload = (e) => {
    setshowSpinner(true);

    const locRef = uploadImage == null ? "" : uploadImage.name;
    if (locRef == "") {
      firedb
        .ref("UPC")
        .child("LOCATION_MAP")
        .set({
          locText: locMapText,
          imgUrl: imgUrl,
        })
        .then(() => {
          setshowSpinner(false);
          setshowToast(true);
        });
    } else {
      var uploadDate = new Date();
      uploadDate =
        uploadDate.getDate() +
        "-" +
        uploadDate.getMonth() +
        "-" +
        uploadDate.getFullYear();
      uploadDate = uploadDate.toString();

      const storageRef = firebase.storage().ref(locRef);
      storageRef.put(uploadImage).on(
        "state_changed",
        (snap) => {
          console.log();
        },
        (error) => {
          console.log(error);
        },
        async () => {
          const url = await storageRef.getDownloadURL();

          firedb
            .ref("UPC")
            .child("LOCATION_MAP")
            .set({
              locText: locMapText,
              imgUrl: url,
            })
            .then(() => {
              setshowSpinner(false);
              setshowToast(true);
            });
        }
      );
    }
  };

  console.log(imgUrl);
  return (
    <div className="EditAboutPageContainer">
      {showSpinner ? (
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Uploading...
        </Button>
      ) : (
        <>
          <div className="toastContainer">
            <Toast
              className="toastPart"
              show={showToast}
              onClose={() => setshowToast(false)}
            >
              <Toast.Header>
                <strong className="me-auto">UPC</strong>
              </Toast.Header>
              <Toast.Body>Location Map Page Updated!</Toast.Body>
            </Toast>
          </div>
          <h3>Location Map Controls</h3>
          <hr />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpload();
            }}
            className=""
          >
            <div className="formEditContainer">
              <label>Location Map Text</label>
              <div className="inputContainer2">
                <ReactQuill
                  className="editor2"
                  value={locMapText}
                  theme="snow"
                  onChange={(e) => {
                    setlocMapText(e);
                  }}
                />
              </div>
              {/* <input
                type="text"
                id="name"
                name="locationMap"
                className=""
                value={locMapText}
                placeholder="Write Text"
                onChange={(e) => setlocMapText(e.target.value)}
              /> */}
            </div>
            <div className="formEditContainer">
              <label>Upload Location Map Image&nbsp; &nbsp;</label>
              <input
                type="file"
                value={undefined}
                onChange={(e) => setuploadImage(e.target.files[0])}
              />
            </div>

            <button className="btn btn-primary">Upload</button>
          </form>
        </>
      )}
    </div>
  );
};

export default LocationMap;
