import React, { useState, useEffect } from "react";
import { Toast, Spinner, Button } from "react-bootstrap";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { firebase } from "../../firebaseConfig";

const firedb = firebase.database();
const ProjectPage = () => {
  const [imgUrl, setimgUrl] = useState(null);
  const [uploadImage, setuploadImage] = useState(null);
  const [ProjectText, setProjectText] = useState("");
  const [showToast, setshowToast] = useState(false);
  const [showSpinner, setshowSpinner] = useState(false);

  useEffect(() => {
    firedb
      .ref("UPC")
      .child("PROJECT_LAOYOUT")
      .once("value", (snapshot) => {
        setProjectText(snapshot.val().proText);
        setimgUrl(snapshot.val().imgUrl);
      });
  }, []);

  console.log(ProjectText, imgUrl);

  const handleUpload = (e) => {
    setshowSpinner(true);

    const ProRef = uploadImage == null ? "" : uploadImage.name;
    console.log(ProRef);
    if (ProRef == "") {
      firebase
        .database()
        .ref("UPC")
        .child("PROJECT_LAOYOUT")
        .set({
          proText: ProjectText,
          imgUrl: imgUrl,
        })
        .then(() => {
          setshowSpinner(false);
          setshowToast(true);
        });
    } else {
      var uploadDate = new Date();
      uploadDate =
        uploadDate.getDate() +
        "-" +
        uploadDate.getMonth() +
        "-" +
        uploadDate.getFullYear();
      uploadDate = uploadDate.toString();
      const storageRef = firebase.storage().ref(ProRef);
      storageRef.put(uploadImage).on(
        "state_changed",
        (snap) => {
          console.log();
        },
        (error) => {
          console.log(error);
        },
        async () => {
          const url = await storageRef.getDownloadURL();

          firebase
            .database()
            .ref("UPC")
            .child("PROJECT_LAOYOUT")
            .set({
              proText: ProjectText,
              imgUrl: url,
            })
            .then(() => {
              setshowSpinner(false);
              setshowToast(true);
            });
        }
      );
    }
  };
  return (
    <div className="EditAboutPageContainer">
      {showSpinner ? (
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Uploading...
        </Button>
      ) : (
        <>
          <div className="toastContainer">
            <Toast
              className="toastPart"
              show={showToast}
              onClose={() => setshowToast(false)}
            >
              <Toast.Header>
                <strong className="me-auto">UPC</strong>
              </Toast.Header>
              <Toast.Body>Project Layout Page Updated!</Toast.Body>
            </Toast>
          </div>
          <h3>Project Layout Controls</h3>
          <hr />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpload();
            }}
            className=""
          >
            <div className="formEditContainer">
              <label>Project Layout Text</label>
              <div className="inputContainer2">
                <ReactQuill
                  className="editor2"
                  value={ProjectText}
                  theme="snow"
                  onChange={(e) => {
                    setProjectText(e);
                  }}
                />
              </div>
              {/*  <input
                type="text"
                id="name"
                name="ProjectLayout"
                className=""
                value={ProjectText}
                placeholder="Write Text"
                onChange={(e) => setProjectText(e.target.value)}
              /> */}
            </div>
            <div className="formEditContainer">
              <label>Upload Project Layout Image</label>
              <input
                type="file"
                value={undefined}
                onChange={(e) => setuploadImage(e.target.files[0])}
              />
            </div>

            <button className="btn btn-primary">Upload</button>
          </form>
        </>
      )}
    </div>
  );
};

export default ProjectPage;
